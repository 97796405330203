<template>
  <div>
    <view-header />

    <f-container topBottom class="user-view">
      <page-tabs v-model="pageTab" :items="items" />

      <v-tabs-items v-model="pageTab">
        <v-tab-item :key="tabsRef.PROFILE.tab">
          <view-profile :ref="tabsRef.PROFILE.ref" />
        </v-tab-item>
        <v-tab-item :key="tabsRef.COURSES.tab">
          <view-courses :ref="tabsRef.COURSES.ref" />
        </v-tab-item>
        <v-tab-item :key="tabsRef.TEAMS.tab">
          <view-teams :ref="tabsRef.TEAMS.ref" />
        </v-tab-item>
      </v-tabs-items>
    </f-container>
  </div>
</template>

<script>
import PageTabs from '../../components/shared/PageTabs.vue';
import UserActivitiesList from '../../components/Users/UserActivitiesList.vue';
import UserCoursesList from '../../components/Users/UserCoursesList.vue';
import UserProfile from '../../components/Users/UserProfile.vue';
import UserTeamsList from '../../components/Users/UserTeamsList.vue';
import ViewContent from '../../components/Users/ViewContent.vue';
import ViewCourses from '../../components/Profile/ViewCourses.vue';
import ViewHeader from '../../components/Users/ViewHeader.vue';
import ViewProfile from '../../components/Profile/ViewProfile.vue';
import ViewTeams from '../../components/Profile/ViewTeams.vue';
import courseUtils from '../../helpers/course_utils';
import fileUtils from '../../helpers/file_utils';

export default {
  components: {
    UserProfile,
    UserCoursesList,
    UserTeamsList,
    UserActivitiesList,
    ViewHeader,
    ViewContent,
    PageTabs,
    ViewProfile,
    ViewCourses,
    ViewTeams,
  },
  data($vm) {
    const tabSlugs = {
      profile: 0,
      courses: 1,
      teams: 2,
      activities: 3,
      conquests: 4,
    };
    let tab = 0;
    if ($vm.$route.params.tab) {
      tab = tabSlugs[$vm.$route.params.tab] || 0;
    }
    return {
      pageTab: 0,
      loadingUser: true,
      user: null,
      tab,
      tabSlugs,
      tabsRef: {
        PROFILE: {
          ref: 'profile',
          tab: 0,
        },
        COURSES: {
          ref: 'courses',
          tab: 1,
        },
        TEAMS: {
          ref: 'teams',
          tab: 2,
        },
      },
      list: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
      },
      itemsCount: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
      },
      flags: {
        loaded: {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
        },
      },
      page: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
        4: 1,
      },
      search: {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
      },
      limit: {
        0: 0,
        1: 10,
        2: 10,
        3: 10,
        4: 10,
      },
    };
  },
  computed: {
    items() {
      return [
        {
          icon: 'mdi-account-outline',
          title: this.$t('labels.profile'),
        },
        {
          icon: 'mdi-school-outline',
          title: this.$t('labels.courses'),
        },
        {
          icon: 'mdi-account-group-outline',
          title: this.$t('labels.teams'),
        },
      ];
    },
    userName() {
      if (this.loadingUser) {
        return '...';
      }
      if (this.foundUser) {
        return this.user.name;
      }
      return this.$t('alerts.users.notFound');
    },
    tabs() {
      const items = [
        {
          key: 'profile',
          label: this.$t('users.view.profile'),
        },
        {
          key: 'courses',
          label: this.$t('users.view.filterCourses'),
        },
        {
          key: 'teams',
          label: this.$t('users.view.filterTeams'),
        },
        {
          key: 'activities',
          label: this.$t('users.view.filterActivities'),
        },
        {
          key: 'conquests',
          label: this.$t('users.view.filterConquests'),
        },
      ];
      return items;
    },
    getList() {
      return this.list[this.tab] || [];
    },
    getCount() {
      return this.itemsCount[this.tab] !== undefined
        ? this.itemsCount[this.tab]
        : 0;
    },
    userID() {
      return this.$route.params.id;
    },
    foundUser() {
      return this.user !== null;
    },
  },
  watch: {
    tab: function(value) {
      this.$nextTick(() => {
        this.loadData(value);
        this.$router.push({
          name: 'user-view',
          params: {
            id: this.userID,
            tab: Object.keys(this.tabSlugs)[value],
          },
        });
      });
    },
    pageTab() {
      this.loadTabData();
    },
  },
  created() {},
  mounted() {
    this.get();
    this.loadTabData();
  },
  methods: {
    loadTabData() {
      let currentTabRef = '';
      switch (this.pageTab) {
        case this.tabsRef.PROFILE.tab:
          currentTabRef = this.tabsRef.PROFILE.ref;
          break;
        case this.tabsRef.COURSES.tab:
          currentTabRef = this.tabsRef.COURSES.ref;
          break;
        case this.tabsRef.TEAMS.tab:
          currentTabRef = this.tabsRef.TEAMS.ref;
      }

      setTimeout(() => {
        this.$refs[currentTabRef].loadData();
      }, 100);
    },
    async loginAs() {
      await this.$store.dispatch('auth/loginAs');
      this.$router.push({
        name: 'course-list',
      });
    },
    async get() {
      await this.loadUser();
      if (this.user) {
        await this.loadData(this.tab);
        this.$store.commit('system/loading', false);
      }
    },
    async loadUser() {
      this.$store.commit('user/setID', this.userID);
      const result = await this.$store.dispatch('user/get');
      if (result) {
        this.user = this.$store.state.user.view;
      }
      this.loadingUser = false;
    },
    async loadData(tabIndex) {
      if (this.list[tabIndex].length === 0 && !this.list[0]) {
        const actions = {
          courses: 'user/courses',
          teams: 'user/teams',
          activities: 'user/activities',
          conquests: 'user/courses',
        };
        const params = {
          courses: {},
          teams: {},
          activities: {},
          conquests: { status: 'done' },
        };
        const tab = this.tabs[tabIndex];
        const action = actions[tab.key];
        this.flags.loaded[tabIndex] = false;
        const { list } = await this.$store.dispatch(action, {
          userID: this.userID,
          ...params[tab.key],
        });
        this.list[tabIndex] = list;
        this.flags.loaded[tabIndex] = true;
      }
    },
    edit() {
      this.$router.push({
        name: 'user-edit',
        params: {
          id: this.userID,
        },
      });
    },
    back() {
      this.$router.back();
    },
    async onDownloadCertificate(course) {
      const courseID = course._id;
      const result = await this.$store.dispatch('user/generateCertificate', {
        courseID: courseID,
        userID: this.userID,
      });
      if (result) {
        const name = courseUtils.certificateFilename(course, this.$i18n.locale);
        fileUtils.download({
          name,
          type: 'application/pdf',
          data: result,
          isBase64: true,
        });
      } else {
        this.$store.commit('snackbar/show', {
          content: this.$t('reports.student.certificateError'),
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.user-view {
  .theme--light.v-tabs-items {
    background-color: transparent !important;
  }
}
</style>
