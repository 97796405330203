<template>
  <div>
    <user-profile v-if="$route.name == 'user-view-profile'" />
    <user-course-list v-if="$route.name == 'user-view-courses'" />
    <user-team-list v-if="$route.name == 'user-view-teams'" />
  </div>
</template>

<script lang="js">
import UserProfile from './UserProfile.vue';
import UserCourseList from './UserCoursesList.vue';
import UserTeamList from './UserTeamsList.vue';

export default {
	components: {
		UserProfile,
		UserCourseList,
		UserTeamList
	}
}
</script>
