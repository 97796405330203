<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :server-items-length="itemsCount"
      :options.sync="options"
      :loading="loading"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
      }"
      :disable-sort="true"
      class="elevation-0"
    >
      <!-- <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('labels.search')"
          class="mx-4"
        ></v-text-field>
      </template> -->
      <template v-slot:[`item.courseName`]="{ item }">
        <!-- <router-link :to="courseViewLink(item)">{{
          getCourseName(item)
        }}</router-link> -->
        {{ getCourseName(item) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColor(item.info.status)" dark small>
          {{ $i18n.t(`labels.status.${item.info.status}`) }}
        </v-chip>
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <div v-if="item.info.progress" class="user-progress">
          <v-progress-circular
            :rotate="-90"
            :size="25"
            :width="4"
            :value="item.info.progress"
            color="primary"
          >
          </v-progress-circular>
          <div class="user-progress__percent caption">
            {{ item.info.progress }}%
          </div>
        </div>
      </template>
      <template v-slot:[`item.done`]="{ item }">
        <v-icon v-if="item.info.done">mdi-check</v-icon>
      </template>
      <template v-slot:[`item.doneDate`]="{ item }">
        {{ item.info.done_date ? $df.datetime(item.info.done_date) : '' }}
      </template>
      <template v-slot:[`item.downloadCertificate`]="{ item }">
        <div v-if="item.info.done">
          <v-btn
            icon
            @click.prevent="$emit('downloadCertificate', item.course)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    itemsCount: Number,
    loading: Boolean,
    title: String,
  },
  data() {
    return {
      search: '',
      options: {},
    };
  },
  computed: {
    headers() {
      const { locale } = this.$i18n;
      return [
        {
          text: this.$tt.capitalize(this.$t('reports.course.course')),
          align: 'start',
          value: 'courseName',
        },
        {
          text: this.$tt.capitalize(this.$t('users.view.statusLabel')),
          align: 'start',
          value: 'status',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.course.progress')),
          align: 'start',
          value: 'progress',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.course.done')),
          align: 'start',
          value: 'done',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.course.doneDate')),
          align: 'start',
          value: 'doneDate',
        },
        {
          text: this.$tt.capitalize(this.$t('labels.downloadCertificate')),
          align: 'start',
          value: 'downloadCertificate',
        },
      ];
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      const result = await this.$store.dispatch('user/courses', {
        userID: this.$route.params.id,
      });
      this.list = result.list;
    },
    getColor(status) {
      const items = {
        pending: '#90A4AE',
        done: '#81C784',
        in_progress: '#73b0e5',
      };
      return items[status] || items.pending;
    },
    getCourseName(item) {
      const { locale } = this.$i18n;
      const { title } = item.course;
      const langs = Object.keys(title);
      if (langs.includes(locale)) {
        return title[locale];
      }
      return title[langs[0]];
    },
    courseViewLink(item) {
      return {
        name: 'course-view',
        params: {
          id: item.course._id,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user-progress {
  display: flex;
  align-items: center;
  &__percent {
    margin-left: 7px;
    font-weight: 700;
  }
}
</style>
