import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.list,"server-items-length":_vm.itemsCount,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"footer-props":{
      'items-per-page-options': [10, 20, 50, 100],
    },"disable-sort":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.courseName`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getCourseName(item))+" ")]}},{key:`item.status`,fn:function({ item }){return [_c(VChip,{attrs:{"color":_vm.getColor(item.info.status),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.$i18n.t(`labels.status.${item.info.status}`))+" ")])]}},{key:`item.progress`,fn:function({ item }){return [(item.info.progress)?_c('div',{staticClass:"user-progress"},[_c(VProgressCircular,{attrs:{"rotate":-90,"size":25,"width":4,"value":item.info.progress,"color":"primary"}}),_c('div',{staticClass:"user-progress__percent caption"},[_vm._v(" "+_vm._s(item.info.progress)+"% ")])],1):_vm._e()]}},{key:`item.done`,fn:function({ item }){return [(item.info.done)?_c(VIcon,[_vm._v("mdi-check")]):_vm._e()]}},{key:`item.doneDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.info.done_date ? _vm.$df.datetime(item.info.done_date) : '')+" ")]}},{key:`item.downloadCertificate`,fn:function({ item }){return [(item.info.done)?_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('downloadCertificate', item.course)}}},[_c(VIcon,[_vm._v("mdi-download")])],1)],1):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }