<template>
  <div>
    <lms-card>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-left">
                Curso
              </th>
              <th class="text-center">
                Status do curso
              </th>
              <th class="text-left">
                Progresso
              </th>
              <th class="text-center">
                Concluído
              </th>
              <th class="text-center">
                Data da conclusão
              </th>
              <th class="text-center">
                Status certificado
              </th>
              <th class="text-center">
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in coursesData"
              :key="item.course._id"
              class="row-courses"
              :id="`row-courses-${item.course._id}`"
            >
              <td class="text-left lms-title">
                {{ getCourseTitle(item.course) }}
              </td>
              <td class="text-center">status</td>
              <td>
                <div class="row-progress">
                  <div>
                    <list-progress-bar
                      :value="item.progress.progress"
                      width="150"
                      linear
                    />
                  </div>
                  <div>{{ item.progress.progress }}%</div>
                </div>
              </td>
              <td class="text-center">
                <v-icon v-if="item.progress.done">mdi-check-bold</v-icon>
                <span v-else>-</span>
              </td>
              <td class="text-center">
                {{
                  item.progress.done
                    ? getFormatedDate(item.progress.done_date)
                    : '-'
                }}
              </td>
              <td class="text-center">-</td>
              <td class="text-center">
                <button @click="showRowDetails(item.course)">
                  show/hide details
                </button>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <tr id="row-details" v-show="rowDetails.showing">
        <td class="text-left lms-title" colspan="7">
          <linear-details
            v-if="
              rowDetails.data.curseStructure &&
                rowDetails.data.curseStructure == 'linear'
            "
            :course-data="rowDetails.data.data.lessons"
            :course-lang="rowDetails.lang"
            :course-id="rowDetails.id"
          />
          <modular-details
            v-if="
              rowDetails.data.curseStructure &&
                rowDetails.data.curseStructure == 'modular'
            "
            :course-data="rowDetails.data.data.modules"
            :course-lang="rowDetails.lang"
            :course-id="rowDetails.id"
          />
        </td>
      </tr>
    </lms-card>
  </div>
</template>

<script>
import * as courseService from '../../services/courses';
import moment from 'moment';
import ListProgressBar from '../shared/ListProgressBar.vue';
import { courseProgress } from '../../services/users';
import LinearDetails from './courseDetails/Linear.vue';
import ModularDetails from './courseDetails/Modular.vue';

export default {
  components: {
    ListProgressBar,
    LinearDetails,
    ModularDetails,
  },
  data: () => ({
    rowDetails: {
      showing: false,
      id: null,
      lang: '',
      data: {},
    },
  }),
  computed: {
    coursesData() {
      try {
        return this.$store.state.report.studentView.courses;
      } catch (e) {
        return [];
      }
    },
  },
  created() {
    // console.log('weee');
    // // row-courses-610310042468f47b4537776f
    // const targetRow = document.getElementById(
    //   'row-courses-610310042468f47b4537776f'
    // );
    // const rowDetails = document.getElementById('row-details');
    // targetRow.insertAdjacentElement('afterend', rowDetails);
  },
  methods: {
    async showRowDetails(course) {
      if (course._id == this.rowDetails.id) {
        this.rowDetails.showing = false;
        this.rowDetails.id = null;
      } else {
        this.rowDetails.showing = true;
        this.rowDetails.data = {};
        await this.$nextTick(() => {
          this.rowDetails.id = course._id;
          const targetRow = document.getElementById(
            `row-courses-${this.rowDetails.id}`
          );
          const rowDetails = document.getElementById('row-details');
          targetRow.insertAdjacentElement('afterend', rowDetails);
        });

        this.rowDetails.data = await courseProgress(
          this.$route.params.id,
          course._id
        );

        const courseLang = courseService.getCurrentLanguage(course);
        this.rowDetails.lang = courseLang;
      }
    },
    loadData() {
      this.rowDetails.showing = false;
      this.$store.dispatch('report/studentData', this.$route.params.id);
    },
    getCourseTitle(course) {
      const courseLang = courseService.getCurrentLanguage(course);
      return course.title[courseLang];
    },
    getFormatedDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss">
.row-progress {
  display: flex;

  height: 100%;
  align-items: center;
  width: 140px;

  & > div:last-child {
    margin-left: 10px;
  }
}

.row-courses {
  td {
    font-size: 16px;
    font-weight: 500;
    padding: 20px 16px !important;
    height: auto !important;
  }

  .lms-title {
    font-weight: 700;
  }
}
#row-details {
  background-color: white !important;
}
.modules-in-details {
  font-weight: bold;
}
table.lessons-in-details {
  width: 100%;

  td:first-child {
    width: 75%;
  }

  .progress-in-details {
    width: 20%;
    display: flex;
    align-items: center;

    & > div:first-child {
      margin-right: 10px;
    }

    div {
      font-size: 10px;
      color: #8d8d8d;
    }
  }

  .action-in-details {
    width: 5%;
  }

  .progress-in-details,
  .action-in-details {
    text-align: right;
  }
}
.reset-slim-btn {
  text-transform: uppercase;
  padding: 1px 10px;
  border-radius: 2px;
  font-size: 10px;

  color: #000;
  background-color: #fab330;

  &:disabled {
    color: #a5a5a5;
    background-color: #f5f6f6;
  }
}
</style>
