import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('lms-card',[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Curso ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Status do curso ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Progresso ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Concluído ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Data da conclusão ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Status certificado ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Ações ")])])]),_c('tbody',_vm._l((_vm.coursesData),function(item){return _c('tr',{key:item.course._id,staticClass:"row-courses",attrs:{"id":`row-courses-${item.course._id}`}},[_c('td',{staticClass:"text-left lms-title"},[_vm._v(" "+_vm._s(_vm.getCourseTitle(item.course))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v("status")]),_c('td',[_c('div',{staticClass:"row-progress"},[_c('div',[_c('list-progress-bar',{attrs:{"value":item.progress.progress,"width":"150","linear":""}})],1),_c('div',[_vm._v(_vm._s(item.progress.progress)+"%")])])]),_c('td',{staticClass:"text-center"},[(item.progress.done)?_c(VIcon,[_vm._v("mdi-check-bold")]):_c('span',[_vm._v("-")])],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.progress.done ? _vm.getFormatedDate(item.progress.done_date) : '-')+" ")]),_c('td',{staticClass:"text-center"},[_vm._v("-")]),_c('td',{staticClass:"text-center"},[_c('button',{on:{"click":function($event){return _vm.showRowDetails(item.course)}}},[_vm._v(" show/hide details ")])])])}),0)]},proxy:true}])}),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.rowDetails.showing),expression:"rowDetails.showing"}],attrs:{"id":"row-details"}},[_c('td',{staticClass:"text-left lms-title",attrs:{"colspan":"7"}},[(
            _vm.rowDetails.data.curseStructure &&
              _vm.rowDetails.data.curseStructure == 'linear'
          )?_c('linear-details',{attrs:{"course-data":_vm.rowDetails.data.data.lessons,"course-lang":_vm.rowDetails.lang,"course-id":_vm.rowDetails.id}}):_vm._e(),(
            _vm.rowDetails.data.curseStructure &&
              _vm.rowDetails.data.curseStructure == 'modular'
          )?_c('modular-details',{attrs:{"course-data":_vm.rowDetails.data.data.modules,"course-lang":_vm.rowDetails.lang,"course-id":_vm.rowDetails.id}}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }