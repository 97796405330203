<template>
  <span :user="user">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <br />
            <span class="text-capitalize" style="font-size:1.5rem">{{
              getUserType(user.type)
            }}</span
            ><br />
            <v-chip :color="getColor(user.status)" dark small>
              {{ $i18n.t(`labels.status.${user.status}`) }}
            </v-chip>
            <v-chip v-if="user.isChampion" color="primary" dark small>
              Champion
            </v-chip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col cols="6">
            <p class="title">{{ $t('labels.personalInfo') }}</p>
            <br />
            <p class="text-capitalize">
              {{ $t('labels.name') }}: <strong>{{ user.name }}</strong
              ><br />
              {{ $t('labels.email') }}:
              <strong
                ><span style="text-transform: lowercase;">{{
                  user.email
                }}</span></strong
              ><br />
              <br />
              {{ $t('labels.dialCode') }}:
              <strong>{{ user.dialCode || '-' }}</strong
              ><br />
              {{ $t('labels.phone') }}: <strong>{{ user.phone || '-' }}</strong
              ><br />
              <br />
              {{ $t('labels.country') }}:
              <strong>{{ countryName(user.country, user.lang) || '-' }}</strong
              ><br />
              {{ $t('labels.state') }}: <strong>{{ user.state || '-' }}</strong
              ><br />
              {{ $t('labels.city') }}: <strong>{{ user.city || '-' }}</strong
              ><br />
              {{ $t('labels.distributor') }}:
              <strong>{{ user.distributor || '-' }}</strong>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="title">{{ $t('labels.LMSInfo') }}</p>
            <br />
            <p>
              {{ $t('labels.flukeAcademyID') }}:
              <strong>{{ user.flukeAcademyID || '-' }}</strong
              ><br />
              <br />
              {{ $t('labels.master') }}:
              <strong>{{
                user.masters.length ? user.masters[0].title : '-'
              }}</strong
              ><br />
              TSM: <strong>{{ TSM || '-' }}</strong
              ><br />
              <br />
              {{ $t('labels.enrollmentDate') }}:
              <strong>{{ formatData(user.createdAt) }}</strong
              ><br />
              {{ $t('labels.championSince') }}:
              <strong>{{
                user.isChampion && user.championSince
                  ? formatChampionDate(user.championSince)
                  : '-'
              }}</strong>
            </p>
          </v-col>
        </v-row>
        <v-row v-if="user.isTSM">
          <v-col>
            <p class="title">{{ $t('labels.TSMInfo') }}</p>
          </v-col>
        </v-row>
        <v-row v-if="user.isTSM">
          <v-col>
            <p>{{ $t('labels.TSMCategory') }}: {{ user.TSM[0].TSMType }}</p>
          </v-col>
          <v-col>
            <p>{{ $t('labels.TSMCountry') }}</p>
            <v-chip
              style="margin-right: 10px"
              v-for="countries in user.TSM[0].country"
              v-bind:key="countries._id"
              dark
              small
            >
              {{ countryName(countries._id, user.lang) }}
            </v-chip>
          </v-col>
          <v-col>
            <p>{{ $t('labels.TSMRegion') }}</p>
            <v-chip
              style="margin-right: 10px"
              v-for="state in user.TSM[0].region"
              v-bind:key="state._id"
              dark
              small
            >
              {{ state.state }}
            </v-chip>
          </v-col>
        </v-row>
        <br />
        <br />
        <hr />
        <br />
        <v-row>
          <v-col>
            <p>{{ $t('labels.trainingCategory') }}:</p>
            <v-chip
              style="margin-right: 10px"
              v-for="category in user.trainingCategory"
              dark
              small
            >
              {{ category }}
            </v-chip>
          </v-col>
          <v-col>
            <p>{{ $t('labels.userClassification') }}:</p>
            <v-chip
              style="margin-right: 10px"
              v-for="classification in user.classification"
              dark
              small
            >
              {{ classification }}
            </v-chip>
          </v-col>
        </v-row>
        <br />
      </v-card-text>
    </v-card>
    <lms-btn-holder>
      <v-btn @click="loginAs">login as this user</v-btn>
    </lms-btn-holder>
  </span>
</template>

<script>
import moment from 'moment';
import { UserType } from '../../helpers/user_utils';

export default {
  data: () => ({
    TSM: null,
  }),
  computed: {
    user() {
      return this.$store.getters['user/current'];
    },
  },
  created() {
    this.$store.dispatch('country/loadList');
    this.getTSM();
  },
  methods: {
    async loginAs() {
      await this.$store.dispatch('auth/loginAs');
      this.$router.push({
        name: 'course-list',
      });
    },
    async getTSM() {
      let user = this.user;
      const result = await this.$store.dispatch('user/getTSM', user._id);
      if (typeof result.count != 'undefined') {
        this.TSM = `${result[0].name} (${result[0].email})`;
      }
    },
    getColor(item) {
      const colors = {
        pending: '#90A4AE',
        enabled: '#81C784',
        disabled: '#FFB74D',
        rejected: '#E57373',
      };
      return colors[item] || colors.generic;
    },
    formatData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    countryName(id, lang) {
      try {
        if (id) {
          let country = this.$store.state.country.list.items.find(
            country => country._id === id
          );
          return country.name[lang];
        }
      } catch (e) {
        return '';
      }
    },
    TSMCountries(ids, lang) {
      let countries = [];
      for (let country of ids) {
        countries.push(this.countryName(country._id, lang));
      }
      return countries;
    },
    getUserType(type) {
      let value = type;
      if (value == UserType.Student) {
        value = this.$t('labels.student');
      }
      return value;
    },
    formatChampionDate(date) {
      let formatedDate = date.split('-');
      return `${formatedDate[1]}/${formatedDate[0]}`;
    },
  },
};
</script>
