<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="formatedList"
      :server-items-length="itemsCount"
      :search="search"
      :options.sync="options"
      :loading="loading"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100]
      }"
      :disable-sort="true"
      class="elevation-0"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('labels.search')"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.typeLabel`]="{ item }">
        <v-chip :color="item.color" dark small>
          {{ item.typeLabel }}
        </v-chip>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span v-html="item.name"></span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ $df.datetime(item.createdAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
    list: Array,
    itemsCount: Number,
    loading: Boolean,
    title: String
  },
  data() {
    return {
      search: '',
      options: {}
    }
  },
  computed: {
    headers() {
      const { locale } = this.$i18n
      return [
        {
          text: this.$tt.capitalize(this.$t('users.view.activityType')),
          align: 'start',
          value: 'typeLabel'
        },
        {
          text: this.$tt.capitalize(this.$t('users.view.activity')),
          align: 'start',
          value: 'name'
        },
        {
          text: this.$tt.capitalize(this.$t('users.view.createdAt')),
          align: 'start',
          value: 'createdAt'
        }
      ]
    },
    formatedList() {
      return this.list.map((item) => {
        return {
          ...item,
          color: this.getColor(item),
          typeLabel: this.getTypeLabel(item),
          name: this.getActivityMessage(item)
        }
      })
    }
  },
  methods: {
    getActivityMessage(item) {
      const messages = {
        course: () =>
          this.$t('users.view.activities.course', {
            name: this.user.name,
            course: item.details.courseName
          }),
        module: () =>
          this.$t('users.view.activities.module', {
            name: this.user.name,
            module: item.details.moduleName
          }),
        user: () =>
          this.$t('users.view.activities.user', {
            name: this.user.name,
            updatedBy: item.details.userName
          })
      }
      if (messages[item.type]) {
        return messages[item.type]()
      }
      return '-'
    },
    getColor(item) {
      const colors = {
        generic: '#9E9E9E',
        course: '#4CAF50',
        module: '#2196F3',
        user: '#FF9800'
      }
      return colors[item.type] || colors.generic
    },
    getTypeLabel(item) {
      return this.$t(`users.view.activiteTypes.${item.type}`)
    }
  }
}
</script>
