<template>
  <div>
    <div v-for="mdl in courseData">
      <div class="modules-in-details">
        {{ mdl.title[courseLang] }}
      </div>
      <table class="lessons-in-details">
        <tr v-for="lesson in mdl.lessons">
          <td>{{ lesson.title[courseLang] }}</td>
          <td class="progress-in-details">
            <div>
              <list-progress-bar :value="lesson.progress" width="150" linear />
            </div>
            <div>{{ lesson.progress }}%</div>
          </td>
          <td class="action-in-details">
            <button
              class="reset-slim-btn"
              v-if="lesson.category == 2 && lesson.attempts.attempts"
              @click="reset(lesson)"
              :disabled="lesson.attempts.approved"
            >
              {{ $t('labels.reset') }}
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ListProgressBar from '../../shared/ListProgressBar.vue';
export default {
  props: ['courseData', 'courseLang', 'courseId'],
  components: {
    ListProgressBar,
  },
  methods: {
    async reset(lesson) {
      await this.$store.dispatch('course/module_exam_set_user_attempts', {
        userID: this.$route.params.id,
        courseID: this.courseId,
        moduleID: lesson._id,
        attempts: 0,
      });
    },
  },
};
</script>
