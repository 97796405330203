<template>
  <div>
    <lms-card>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-left">
                Time
              </th>
              <th class="text-left">
                Lider
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in teams" :key="item._id" class="row-teams">
              <td class="text-left lms-title">{{ item.name }}</td>
              <td class="text-left">
                <div class="lms-leader">
                  <div>
                    <img :src="avatarImageURL(item.leader)" />
                  </div>
                  <div>
                    {{ item.leader.name }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </lms-card>
  </div>
</template>

<script>
import { avatarImageURL } from '../../services/file';
export default {
  data: () => ({
    teams: [],
  }),
  methods: {
    avatarImageURL,
    async loadData() {
      const result = await this.$store.dispatch('user/teams', {
        userID: this.$route.params.id,
      });
      this.teams = result.list;
    },
  },
};
</script>

<style lang="scss">
.row-teams {
  font-size: 16px;
  font-weight: 500;

  .lms-title {
    font-weight: 700;
  }

  .lms-leader {
    display: flex;
    padding: 8px 0;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      border-radius: 8px;
      object-fit: cover;
      margin-right: 8px;
    }
  }
}
</style>
