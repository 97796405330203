<template>
  <f-page-header :title="title" :prevLinks="prevLinks" />
</template>

<script>
export default {
  computed: {
    title() {
      return this.$store.state.user.view.name;
    },
    prevLinks() {
      const route = this.$route.name;
      var links = [
        {
          title: this.$t('labels.users'),
          route: {
            name: 'user-list',
          },
        },
      ];

      if (route == 'user-list') links = [];

      return links;
    },
  },
};
</script>
