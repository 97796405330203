import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.formatedList,"server-items-length":_vm.itemsCount,"search":_vm.search,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"footer-props":{
      'items-per-page-options': [10, 20, 50, 100]
    },"disable-sort":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VTextField,{staticClass:"mx-4",attrs:{"label":_vm.$t('labels.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:`item.typeLabel`,fn:function({ item }){return [_c(VChip,{attrs:{"color":item.color,"dark":"","small":""}},[_vm._v(" "+_vm._s(item.typeLabel)+" ")])]}},{key:`item.name`,fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$df.datetime(item.createdAt))+" ")]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }