<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :server-items-length="itemsCount"
      :options.sync="options"
      :loading="loading"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
      }"
      :disable-sort="true"
      class="elevation-0"
    >
      <!-- <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('labels.search')"
          class="mx-4"
        ></v-text-field>
      </template> -->
      <template v-slot:[`item.name`]="{ item }">
        <!-- <router-link :to="teamViewLink(item)">{{ item.name }}</router-link> -->
        {{ item.name }}
      </template>
      <template v-slot:[`item.is_leaders`]="{ item }"
        >{{ item.leaders }}
        <v-chip v-if="item.is_leader" color="#81C784" dark small>
          {{ $i18n.t('users.view.yes') }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    itemsCount: Number,
    loading: Boolean,
    title: String,
  },
  data() {
    return {
      search: '',
      options: {},
    };
  },
  computed: {
    headers() {
      const { locale } = this.$i18n;
      return [
        {
          text: this.$tt.capitalize(this.$t('users.view.team')),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$tt.capitalize(this.$t('users.view.leader')),
          align: 'start',
          value: 'is_leader',
        },
      ];
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      const result = await this.$store.dispatch('user/teams', {
        userID: this.$route.params.id,
      });
      this.list = result.list;
    },
    teamViewLink(item) {
      return {
        name: 'team-view',
        params: {
          id: item._id,
        },
      };
    },
  },
};
</script>
